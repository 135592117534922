.sidebar {
  width: 250px;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 100%;
}

.sidebar.collapsed {
  width: 50px !important;
}
.package-selector {
  margin-bottom: 20px;
}
.package-dropdown {
  height: 50px;
  width: 100%;
}


.menu-items {
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: #fff;
  border-radius: 10px;
}

.menu-items li {
  margin: 25px 0;
  font-size: 18px;
}

.menu-items li a {
  text-decoration: none;
  color: #333;
  display: flex;
  align-items: center;
  padding: 10px 0px;
}

.menu-items li a .icon {
  margin-right: 10px;
}

.menu-items li a:hover {
  color: #007bff;
}

.logout {
  margin-top: 20px;
}

.logout a {
  color: #d9534f;
}

.toggle-sidebar {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px; /* Adjust as needed */
  color: #000;
  position: absolute;
    bottom: 0px;
}

.toggle-icon {
  font-size: 36px;
}