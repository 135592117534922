.upload-creatives {
    display: flex;
    flex-direction: column;
  }
  
  .upload-creatives button {
    background-color: #1F4690;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .btn-container {
    display: flex;
    align-self: end;
    margin-top: 10px;
  }
  .btn {
    padding: 10px 22px;
    /* width: 7.5%; */
  }
  .upload-creatives button:hover {
    background-color: #0056b3;
  }
  .upload-creatives .btn.cancel-btn {
    background-color: #dc3545;
    margin-right: 10px;
  }
  