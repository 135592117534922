.trackers .modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: white;
    padding: 20px;
    box-shadow: 24px;
  }
  .add-tracker form {
    background: white;
    width: 50%;
    margin: auto;
    padding: 2%;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .add-tracker .form-group {
    margin-bottom: 15px;
  }
  
  .add-tracker .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .add-tracker .form-group input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  .add-tracker .form-actions {
    display: flex;
    justify-content: space-between;
  }
  
  .add-tracker .btn {
    padding: 10px 20px;
    background-color: #6f42c1;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .add-tracker .btn:hover {
    background-color: #5a34a1;
  }
  .row-flex {
    display: flex;
    justify-content: space-between;
  }
 .row-flex .form-group,
 .row-flex label {
    width: 48%;
  }


  .add-tracker {
    padding: 20px;
  }
  
  .row-flex {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  
  .form-group {
    flex: 1;
  }
  
  .basic-details,
  .frequency-cap {
    margin-bottom: 20px;
  }
  
  .form-actions {
    display: flex;
    justify-content: space-between;
  }
  
  .form-actions .btn {
    padding: 10px 20px;
    cursor: pointer;
  }
  #mui-component-select-cappingTimeframe {
    padding: 14px !important;
  }
  #creativeid-cont > div{
    height: 51px;
  }
  .custom-trackers-field > div > div{
    height: 51px;
  }

  .link-modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 25px;
    border-radius: 15px;
    box-shadow: 0px 10px 15px rgba(0,0,0,0.1);
    width: 48%;
  }
  
  .link-modal-box .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ebedf9;
    padding-bottom: 15px;
  }

  .link-modal-box .header span {
    font-size: 21px;
    font-weight: 600;
  }

  .field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #757171;
    border-radius: 4px;
    padding: 0 5px;
    margin: 12px 0 -5px 0;
    height: 45px;
  }
  
  .url-icon {
    width: 50px !important;
    font-size: 18px !important;
    text-align: center;
  }
  
  .input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 15px;
  }
  
  .copy-button {
    color: #fff !important;
    padding: 5px 18px !important;
    background: #7d2ae8 !important;
    transition: background 0.1s linear !important;
  }
  
  .copy-button:hover {
    background: #8d39fa;
  }
  
  .close-button {
    color: #878787;
    background: #f3f3f3;
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
  }
  
  .close-button:hover {
    background: #ebedf9;
  }
  .field button {
    outline: none;
    cursor: pointer;
    font-weight: 500;
    border-radius: 4px;
    border: 2px solid transparent;
    transition: background 0.1s linear, border-color 0.1s linear, color 0.1s linear;
  }
  .ct-delete-icon svg{
    position: relative;
    top: -15%;
  }