.profile-card {
    display: flex;
  }
  
  .profile-image {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .profile-card h3 {
    margin: 5px 0px;
    font-size: 18px;
    color: #fff;
  }
  
  .profile-card p {
    color: #fff;
    font-size: 14px;
    margin: 0px;
  }
  .avatar img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
  }
  